import * as React from 'react';
import { Menu, Notifications, SwitchErrorInfo } from 'piral';
import { Link } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { PrimaryAppBar } from './components'

const MenuItem = ({ children }) => <li className="nav-item">{children}</li>;

const defaultTiles = "";

export const errors = {
  not_found: () => (
    <div>
      <p className="error">Could not find the requested page. Are you sure it exists?</p>
      <p>
        Go back <Link to="/">to the dashboard</Link>.
      </p>
    </div>
  ),
};

export const layout = {
  
  ErrorInfo: props => (
    <div>
      <h1>Error</h1>
      <SwitchErrorInfo {...props} />
    </div>
  ),
  DashboardContainer: ({ children }) => (
    <div>
      <h1>Welcome to Brightspots!</h1>
    </div>
  ),
  Layout: ({ children }) => (
    <div>
      <CssBaseline />
      <Notifications />
      <Menu type="general" />
      <div className="container">{children}</div>
    </div>      
  ),
  MenuContainer: ({ children }) => {
    const [collapsed, setCollapsed] = React.useState(true);
    return (
      <PrimaryAppBar apps={children} ></PrimaryAppBar>
    );
  },
  MenuItem,
  NotificationsHost: ({ children }) => <div className="notifications">{children}</div>,
  NotificationsToast: ({ options, onClose, children }) => (
    <div className={`notification-toast ${options.type}`}>
      <div className="notification-toast-details">
        {options.title && <div className="notification-toast-title">{options.title}</div>}
        <div className="notification-toast-description">{children}</div>
      </div>
      <div className="notification-toast-close" onClick={onClose} />
    </div>
  ),
  LoadingIndicator: ({}) => (
    <div className="v-center h-center">
      <div className="loading-spinner">
        Loading New...
      </div>
    </div>
  ),
};
