import { colors } from '@material-ui/core';
import color from '@material-ui/core/colors/amber';
import { isWhiteSpaceLike } from 'typescript';

const white = '#FFFFFF';
const black = '#000000';
const brightspotsBackground = '#F3FFFC';
const brightspotsDarkBlue = '#000031';
const brightspotsBrightBlue = '#09F5ED';
const brightspotsBrightGreen = '#06F6A8';
const brightspotsDullPurple = '#799AE0';
const brightspotsOrange = '#FF8126';
const brightspotsPink = '#FF117E';
const brightspotsOffWhite = '#F3FFFC';

export default {

  brightspots:{
    primary: brightspotsBackground,
    background: white,
    contrastText: white,
    dark: white,
    main: brightspotsBackground,
    light: white
  },
  primary: {
    background: white,
    contrastText: white,
    dark: white,
    main: white,
    light: white
  },
  secondary: {
    contrastText: white,
    dark: white,
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
};
