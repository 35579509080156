import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';

const customTheme = createMuiTheme({
  palette,
//   typography,
 // overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default customTheme;
